<template>
  <div class="relative h-32">
    <select
      v-model="model"
      class="h-full w-full pr-[35px] text-center bg-lightest type-headline-sm uppercase cursor-pointer"
      :class="[selectClass, {
        'text-end': textAlign === 'end',
        'text-start': textAlign !== 'end',
      }]"
      @change="(e) => $emit('change', e.target.value)"
    >
      <option
        v-for="item in options"
        :key="`dropdown-status-item-${item.value}`"
        :value="item.value"
      >
        {{ item.label }}
      </option>
    </select>
    <div
      class="absolute w-32 bg-lightest pointer-events-none top-0 right-0 bottom-0 flex justify-center items-center"
    >
      <img
        src="/icons/chevron-up.svg"
        class="inline-block w-16 h-16 rotate-180 -translate-y-1"
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
const model = defineModel<string>();
defineProps<{
  options: { label: string; value: string }[];
  textAlign?: 'start' | 'end';
  selectClass?:string;
}>();

defineEmits<{
  (e: 'change', payload: string): void;
}>();
</script>
